"use strict";

(function () {
  window.StReloadOnError = {
    data: function data() {
      return {
        request: this.$root.request,
        requestParams: this.$root.requestParams,
        targeDiv: document.getElementById(this.$root.targetContainerId)
      };
    },
    mounted: function mounted() {
      var _this = this;
      this.targeDiv.display = 'none';
      this.request(this.requestParams).then(function (success) {
        if (success) {
          _this.$el.style.display = 'none';
          _this.targeDiv.style.display = 'block';
        } else {
          _this.$el.style.display = 'block';
          _this.targeDiv.style.display = 'none';
        }
      });
    },
    methods: {
      retryRequest: function retryRequest() {
        var _this2 = this;
        var iconWrapper = document.getElementById('st-reload-onerror__icon-wrapper');
        var errorMessageDiv = document.getElementById('st-reload-onerror__errormsg');
        var animationTime = 1000;
        iconWrapper.classList.add('st-reload-onerror__animate');
        errorMessageDiv.innerHTML = 'Obtendo dados...';
        this.request(this.requestParams).then(function (success) {
          setTimeout(function () {
            iconWrapper.classList.remove('st-reload-onerror__animate');
            errorMessageDiv.animate([{
              color: 'var(--white)'
            }, {
              color: 'var(--red-600)'
            }], {
              easing: 'ease-in',
              duration: animationTime
            });
            if (success) {
              _this2.$el.style.display = 'none';
              _this2.targeDiv.style.display = 'block';
            } else {
              errorMessageDiv.innerHTML = 'Falha ao obter dados';
              _this2.$el.style.display = 'block';
              _this2.targeDiv.style.display = 'none';
            }
          }, animationTime);
        });
      }
    },
    template: "\n\t\t\t<div class=\"st-reload-onerror__wrapper\">\n\t\t\t\t<div id=\"st-reload-onerror__errormsg\">\n\t\t\t\t\tFalha ao obter dados\n\t\t\t\t</div>\n\n\t\t\t\t<button class=\"st-reload-onerror\" @click=\"retryRequest\">\n\t\t\t\t\t<div id=\"st-reload-onerror__icon-wrapper\">\n\t\t\t\t\t\t<svg class=\"st-reload-onerror__icon\">\n\t\t\t\t\t\t\t<use href=\"#icon-reload\"></use>\n\t\t\t\t\t\t</svg>\n\t\t\t\t\t</div>\n\n\t\t\t\t\t<div class=\"st-reload-onerror__text\">\n\t\t\t\t\t\tRECARREGAR\n\t\t\t\t\t</div>\n\t\t\t\t</button>\n\t\t\t</div>\n\t\t"
  };
})();